import * as React from 'react';
import {SimpleForm} from 'react-admin';
import useCustomEditController from "../../hooks/UseCustomEditControllerHook";
import ArticleRefForm from "../forms/ArticleRefForm";

export const ArticleRefFormEdit = (props: any) => {
    const {record, isLoading, handleClick} = useCustomEditController(
        {queue: 'admin-get-article-ref', type: 'edit' , redirect_to_list: 'admin-articles-ref-list'});

    if (isLoading) return null;

    return (
        <div>
            <SimpleForm record={record} onSubmit={handleClick}>
                <ArticleRefForm/>
            </SimpleForm>
        </div>
    )

};

export default ArticleRefFormEdit