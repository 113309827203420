import * as React from "react";
import {
    BooleanInput,
    CreateButton,
    Datagrid,
    EditButton,
    ExportButton,
    FilterButton,
    List,
    TextField, TextInput,
    TopToolbar
} from 'react-admin';
import BooleanFieldWithContext from "../elements/BooleanFieldWithContext";
import DeleteCustomButton from "../elements/ButtonCustomDelete";


const PostListActions = () => (
    <TopToolbar>
        {/*<SelectColumnsButton />*/}
        <FilterButton/>
        <CreateButton/>
        <ExportButton/>
    </TopToolbar>
);


// Определение фильтров с использованием полей из модели SubjectResponse
const postFilters = [
    <TextInput name="code" label="Код" source="text" defaultValue=""/>,
    <TextInput name="parentId" label="Родитель" source="parentId" defaultValue=""/>,
    <TextInput name="name" label="Название" source="periodTypeId" defaultValue=""/>,
    <BooleanInput name="active" label="Активен" source="periodEnd" defaultValue=""/>
];

// Определение сервисов
const services = {'services': 'account_service'};

// Экспорт списка субъектов с использованием обновленных полей
export const serviceCategoryRefList = (props: any) => (
    <List
        {...props}
        actions={<PostListActions/>}
        filters={postFilters}
        filter={services}
    >
        <Datagrid>

            <TextField source="code" label="Код"/>
            <TextField source="parent.name" label="Родитель"/>
            <TextField source="name" label="Название"/>
            <BooleanFieldWithContext source="active" label="Активен"/>
            <EditButton/>
            <DeleteCustomButton
                queue="admin-get-service-category-ref-for-delete"
                type="delete"
                redirect_path="/admin-services-categories-ref-list"
            />
        </Datagrid>
    </List>
);


