import {NumberInput, DateInput} from 'react-admin';
import * as React from 'react';
import CustomReferenceInput from "../elements/CustomReferenceInput";


const AdviceRuleRefForm: React.FC = () => {
    return (
        <div>
            <NumberInput
                source="ageFrom"
                name="ageFrom"
                label="Возраст (от)"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <NumberInput
                source="ageTo"
                name="ageTo"
                label="Возраст (до)"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <DateInput
                source="publicDate"
                name="publicDate"
                label="Дата публикации"
            />
            <CustomReferenceInput
                source="genderCode"
                reference="admin-genders"
                label="Пол"
                name="genderCode"
                filter={{services: 'account_service', resource: "admin-genders-list"}}
                meta={{resource: 'admin-genders', model_name: 'GenderRef'}}
            />
            <CustomReferenceInput
                source="roleCode"
                reference="admin-roles"
                label="Роль"
                name="roleCode"
                filter={{services: 'account_service', resource: "admin-roles-list"}}
                meta={{resource: 'admin-roles', model_name: 'RoleRef'}}
            />
            <CustomReferenceInput
                source="languageCode"
                reference="admin-languages"
                label="Язык"
                name="languageCode"
                filter={{services: 'account_service', resource: "admin-languages-list"}}
                meta={{resource: 'admin-languages', model_name: 'LanguagesRef'}}
            />
        </div>
    );
};

export default AdviceRuleRefForm;
