import {required, TextInput} from 'react-admin';
import * as React from 'react';


const PeriodTypeRefForm: React.FC = () => {
    return (
        <div>

            <TextInput
                source="name"
                name="name"
                label="Название"
                validate={[required()]}
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <TextInput
                source="code"
                name="code"
                label="Код"
                validate={[required()]}
                sx={{marginRight: '10px', fontSize: '10px'}}
            />

        </div>
    );
};

export default PeriodTypeRefForm;
