import * as React from 'react';
import {SimpleForm} from 'react-admin';
import useCustomEditController from "../../hooks/UseCustomEditControllerHook";
import ServiceRefForm from '../forms/ServiceRefForm';

export const ServiceRefFormEdit = (props: any) => {
    const {record, isLoading, handleClick} = useCustomEditController(
        {queue: 'admin-get-service-ref', type: 'edit'});

    if (isLoading) return null;

    return (
        <div>
            <SimpleForm record={record} onSubmit={handleClick}>
                <ServiceRefForm/>
            </SimpleForm>
        </div>

    )

};

export default ServiceRefFormEdit