import {required, TextInput} from 'react-admin';
import * as React from 'react';
import CustomReferenceInput from '../elements/CustomReferenceInput';


const SymptomValueForm: React.FC = () => {
    return (
        <div>

            <CustomReferenceInput
                source="symptomId"
                reference="admin-symptoms-ref"
                label="Симптом"
                name="symptomId"
                filter={{services: 'account_service', resource: "admin-symptoms-ref-list"}}
                meta={{resource: 'admin-symptoms-ref', model_name: 'SymptomRef'}}
            />

            <TextInput
                source="name"
                name="name"
                label="Название"
                validate={[required()]}
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <TextInput
                source="code"
                name="code"
                label="Код"
                validate={[required()]}
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <TextInput
                source="iconPath"
                name="iconPath"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />

        </div>
    );
};

export default SymptomValueForm;
