import * as React from "react";
import {BooleanField, FieldProps, useRecordContext} from "react-admin";

const BooleanFieldWithContext: React.FC<FieldProps> = ({...props}) => {
    /**
     * Рендерит компонент boolean field в контексте.
     * Окрашивает текст в зависимости от статуса записи.
     *
     * @param {FieldProps} props - Объект props, содержащий свойства поля.
     * @return {React.ReactNode} Рендер компонента boolean field в контексте.
     */

    const record = useRecordContext();
    const textStyle = record && record.active ? {color: 'green'} : {color: 'red'};

    return (
        <span style={textStyle}>
      <BooleanField {...props} record={record}/>
    </span>
    );
};

export default BooleanFieldWithContext