import * as React from 'react';
import {SimpleForm} from 'react-admin';
import RoleRefForm from '../forms/RoleRefForm';
import useCustomCreateController from '../../hooks/UseCustomCreateControllerHook';


export const ArticleCategoryRefFormCreate = (props: any) => {
    const {isLoading, handleClick} = useCustomCreateController({
        queue: 'admin-get-article-category-ref',
        type: 'create',
        redirect_path: 'admin-articles-category-ref-list'
    });

    if (isLoading) return null;

    return (
        <div>
            <SimpleForm onSubmit={handleClick}>
                <RoleRefForm/>
            </SimpleForm>
        </div>
    );
};


export default ArticleCategoryRefFormCreate