import {
    ImageField,
    ImageInput,
    NumberInput,
    TextInput
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import * as React from 'react';
import CustomReferenceInput from "../elements/CustomReferenceInput";


const CalendarPeriodRefForm: React.FC = () => {
    return (
        <div>

            <RichTextInput
                source="text"
                name="text"
                label="Текст"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />

            <CustomReferenceInput
                source="calendarId"
                reference="admin-calendars"
                label="Календарь"
                name="calendarId"
                filter={{services: 'account_service', resource: "admin-calendars-ref-list"}}
                meta={{resource: 'admin-calendars', model_name: 'CalendarRef'}}
            />
            <CustomReferenceInput
                source="periodTypeId"
                reference="admin-period-types"
                label="Тип периода"
                name="periodTypeId"
                filter={{services: 'account_service', resource: "admin-periods-type-ref-list"}}
                meta={{resource: 'admin-period-types', model_name: 'PeriodTypeRef'}}
            />
            <NumberInput
                source="periodBegin"
                name="periodBegin"
                label="Начало периода"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <NumberInput
                source="periodEnd"
                name="periodEnd"
                label="Конец периода"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <ImageInput
                accept="image/*"
                multiple={false}
                source="image"
                name="image"
                placeholder={<p>Перетащите изображение</p>}>
                <ImageField source="src" title="title"/>
            </ImageInput>


        </div>
    );
};

export default CalendarPeriodRefForm;
