import {
    ArrayInput,
    BooleanInput,
    NumberInput,
    required,
    SimpleFormIterator,
    TextInput,
    TranslatableInputs,
    SelectInput,
    FormDataConsumer
} from 'react-admin';
import * as React from 'react';
import CustomReferenceInput from "../elements/CustomReferenceInput";


const ServiceRefForm: React.FC = () => {
    return (
        <div>

            <TextInput
                source="code"
                name="code"
                label="Код"
                sx={{ marginRight: '10px', fontSize: '10px' }}
            />

            <TextInput
                source="name"
                name="name"
                label="Название"
                sx={{ marginRight: '10px', fontSize: '10px' }}
            />

            <BooleanInput
                source="active"
                name="active"
                label="Активен"
                sx={{ marginRight: '10px', fontSize: '10px' }}
            />

            <TextInput
                source="phone"
                name="phone"
                label="Телефон"
                sx={{ marginRight: '10px', fontSize: '10px' }}
            />

            <TextInput
                source="address"
                name="address"
                label="Адрес"
                sx={{ marginRight: '10px', fontSize: '10px' }}
            />

            <TextInput
                multiline
                source="text"
                name="text"
                label="Текст"
                validate={[required()]}
                sx={{ marginRight: '10px', fontSize: '10px', width: '100%' }}
                resettable
            />

            <CustomReferenceInput
                source="organizationId"
                reference="admin-organizations"
                label="Организация"
                name="organizationId"
                filter={{
                    services: 'account_service',
                    resource: "admin-organizations-ref-list"
                }}
                meta={{
                    resource: 'admin-organizations',
                    model_name: 'OrganizationRef'
                }}
            />

            <CustomReferenceInput
                source="serviceCategoryId"
                reference="admin-services-categories"
                label="Категория сервиса"
                name="serviceCategoryId"
                filter={{
                    services: 'account_service',
                    resource: "admin-services-categories-ref-list"
                }}
                meta={{
                    resource: 'admin-services-categories',
                    model_name: 'ServicesCategoriesRef'
                }}
            />

            <ArrayInput source="services" label="Услуги">
                <SimpleFormIterator inline>
                    <NumberInput
                        source="price"
                        label="Стоимость услуги"
                        validate={required()}
                        sx={{ fontSize: '10px' }}
                    />

                    <FormDataConsumer>
                        {({
                            getSource,
                            formData, // The whole form data
                            scopedFormData, // The data for this item of the ArrayInput
                        }) =>
                            <TranslatableInputs locales={['en', 'fr']}>
                                <TextInput
                                    source={getSource("feature")}
                                    label="Услуга"
                                />
                            </TranslatableInputs>
                        }
                    </FormDataConsumer>

                    <TextInput
                        source="workFlow"
                        label="График работы"
                        sx={{ fontSize: '10px' }}
                    />

                    <TextInput
                        source="code"
                        label="Код услуги"
                        sx={{ fontSize: '10px' }}
                    />

                    <SelectInput
                        choices={[
                            { id: 'kzt', name: 'KZT' },
                            { id: 'rub', name: 'RUB' },
                            { id: 'usd', name: 'USD' }
                        ]}
                        label="Валюта"
                        sx={{ fontSize: '10px' }}
                        source="currencyCode"
                    />
                </SimpleFormIterator>
            </ArrayInput>
        </div>
    );
};

export default ServiceRefForm;



