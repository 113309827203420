import * as React from "react";
import {
    CreateButton,
    Datagrid,
    EditButton,
    ExportButton,
    FilterButton,
    List,
    TextField, TextInput,
    TopToolbar
} from 'react-admin';
import DeleteCustomButton from "../elements/ButtonCustomDelete";


const PostListActions = () => (
    <TopToolbar>
        {/*<SelectColumnsButton />*/}
        <FilterButton/>
        <CreateButton/>
        <ExportButton/>
    </TopToolbar>
);


// Определение фильтров с использованием полей из модели SubjectResponse
const postFilters = [
    <TextInput name="age" label="Возраст" source="age" defaultValue=""/>,
    <TextInput name="genderCode" label="Пол" source="genderCode" defaultValue=""/>,
    <TextInput name="roleCode" label="Роль" source="roleCode" defaultValue=""/>,
    <TextInput name="languageCode" label="Язык" source="languageCode" defaultValue=""/>
];

// Определение сервисов
const services = {'services': 'account_service'};

// Экспорт списка субъектов с использованием обновленных полей
export const articleRuleRefList = (props: any) => (
    <List
        {...props}
        actions={<PostListActions/>}
        filters={postFilters}
        filter={services}
    >
        <Datagrid>
            <TextField source="agesInfo" label="Возраст"/>
            <TextField source="gender.name" label="Пол"/>
            <TextField source="role.name" label="Роль"/>
            <TextField source="period" label="Период"/>
            <TextField source="language.name" label="Язык"/>
            <EditButton/>
            <DeleteCustomButton
                queue="admin-get-article-rule-ref-for-delete"
                type="delete"
                redirect_path="/admin-article-rule-ref-list"
            />
        </Datagrid>
    </List>
);


