import * as React from 'react';
import {SimpleForm} from 'react-admin';
import RoleRefForm from '../forms/RoleRefForm';
import useCustomEditController from "../../hooks/UseCustomEditControllerHook";


export const ArticleCategoryRefFormEdit = (props: any) => {
    const {record, isLoading, handleClick} = useCustomEditController(
        {queue: 'admin-get-article-category-ref', type: 'edit'}
    );

    if (isLoading) return null;

    return (
        <div>
            <SimpleForm record={record} onSubmit={handleClick}>
                <RoleRefForm/>
            </SimpleForm>
        </div>

    )
};

export default ArticleCategoryRefFormEdit
