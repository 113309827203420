import * as React from 'react';
import {SimpleForm} from 'react-admin';
import useCustomEditController from "../../hooks/UseCustomEditControllerHook";
import ServiceCategoryRefForm from '../forms/ServiceCategoryRefForm';

export const ServiceCategoryRefFormEdit = (props: any) => {
    const {record, isLoading, handleClick} = useCustomEditController(
        {queue: 'admin-get-service-category-ref', type: 'edit'});

    if (isLoading) return null;

    return (
        <div>
            <SimpleForm record={record} onSubmit={handleClick}>
                <ServiceCategoryRefForm/>
            </SimpleForm>
        </div>

    )

};

export default ServiceCategoryRefFormEdit