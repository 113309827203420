import {NumberInput} from 'react-admin';
import * as React from 'react';
import CustomReferenceInput from "../elements/CustomReferenceInput";


const EventRefForm: React.FC = () => {
    return (
        <div>

            <NumberInput
                source="period"
                name="period"
                label="Период"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <CustomReferenceInput
                source="calendarId"
                reference="admin-calendars"
                label="Индификатор calendar"
                name="calendarId"
                filter={{services: 'account_service', resource: "admin-calendars-ref-list"}}
                meta={{resource: 'admin-calendars', model_name: 'CalendarRef'}}
            />
            <CustomReferenceInput
                source="periodTypeId"
                reference="admin-period-types"
                label="Индификатор period"
                name="periodTypeId"
                filter={{services: 'account_service', resource: "admin-periods-type-ref-list"}}
                meta={{resource: 'admin-period-types', model_name: 'PeriodTypeRef'}}
            />
            <CustomReferenceInput
                source="eventTypeId"
                reference="admin-event-types-ref"
                label="Индификатор типа события"
                name="eventTypeId"
                filter={{services: 'account_service', resource: "admin-events-type-ref-list"}}
                meta={{resource: 'admin-event-types-ref', model_name: 'EventTypeRef'}}
            />

        </div>
    );
};

export default EventRefForm;
