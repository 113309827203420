import * as React from "react";
import {
    CreateButton,
    Datagrid,
    EditButton,
    ExportButton,
    FilterButton,
    List,
    TextField,
    TextInput,
    TopToolbar
} from 'react-admin';
import DeleteCustomButton from "../elements/ButtonCustomDelete";


const PostListActions = () => (
    <TopToolbar>
        <FilterButton/>
        <CreateButton/>
        <ExportButton/>
    </TopToolbar>
);

const postFilters = [
    <TextInput name="name" label="Название" source="name" defaultValue=""/>,
    <TextInput name="code" label="Код" source="code" defaultValue=""/>,
];

const services = {'services': 'account_service'}

export const calendarRefList = (props: any) => (
    <List
        {...props}
        actions={<PostListActions/>}
        filters={postFilters}
        filter={services}
    >
        <Datagrid>
            <TextField source="name" label="Название"/>
            <TextField source="code" label="Код"/>
            <EditButton/>
            <DeleteCustomButton
                queue="admin-get-calendar-ref-for-delete"
                type="delete"
                redirect_path="/admin-calendars-ref-list"
            />
        </Datagrid>
    </List>
);

