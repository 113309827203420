import {ImageField, ImageInput, required, TextInput} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import * as React from 'react';
import CustomReferenceInput from "../elements/CustomReferenceInput";


const ArticleRefForm: React.FC = () => {
    return (
        <div>

            <TextInput
                source="code"
                name="code"
                label="Код"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <RichTextInput source="text" />
            <ImageInput
                accept="image/*"
                multiple={false}
                source="image"
                name="image"
                label="Изображение"
                placeholder={<p>Перетащите изображение</p>}>
                <ImageField source="src" title="title"/>
            </ImageInput>
            <CustomReferenceInput
                source="languageCode"
                reference="admin-languages"
                label="Язык"
                name="languageCode"
                filter={{services: "account_service", resource: "admin-languages-list"}}
                meta={{resource: "admin-languages", model_name: "LanguagesRef"}}
            />
            <CustomReferenceInput
                source="articleRuleRefId"
                reference="admin-articles-rule-ref"
                label="Правило"
                name="articleRuleRefId"
                filter={{services: "account_service", resource: "admin-articles-rule-ref-list"}}
                meta={{resource: "admin-articles-rule-ref", model_name: "ArticleRuleRef"}}
            />
            <CustomReferenceInput
                source="categoryId"
                reference="admin-articles-category"
                label="Категория"
                name="categoryId"
                filter={{services: "account_service", resource: "admin-articles-category-ref-list"}}
                meta={{resource: "admin-articles-category", model_name: "ArticleCategory"}}
            />

        </div>
    );
};

export default ArticleRefForm;
