import * as React from 'react';
import {SimpleForm} from 'react-admin';
import useCustomCreateController from '../../hooks/UseCustomCreateControllerHook';
import CalendarPeriodRefForm from "../forms/CalendarPeriodRefForm";


export const CalendarPeriodRefFormCreate = (props: any) => {
    const {isLoading, handleClick} = useCustomCreateController({
        queue: 'admin-get-calendar-period-ref',
        type: 'create',
        redirect_path: 'admin-calendars-periods-ref-list'
    });

    if (isLoading) return null;

    return (
        <div>
            <SimpleForm onSubmit={handleClick}>
                <CalendarPeriodRefForm/>
            </SimpleForm>
        </div>
    );
};


export default CalendarPeriodRefFormCreate