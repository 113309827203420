import * as React from 'react';
import {SimpleForm} from 'react-admin';
import MedicalParametersRefForm from '../forms/MedicalParametersRefForm';
import useCustomEditController from "../../hooks/UseCustomEditControllerHook";


export const MedicalParametersRefFormEdit = (props: any) => {
    const {record, isLoading, handleClick} = useCustomEditController(
        {queue: 'admin-get-medical-parameters', type: 'edit'}
    );

    if (isLoading) return null;

    return (
        <div>
            <SimpleForm record={record} onSubmit={handleClick}>
                <MedicalParametersRefForm/>
            </SimpleForm>
        </div>

    )
};

export default MedicalParametersRefFormEdit
