import React, {useState} from 'react';
import {
    useDataProvider,
    ReferenceInput,
    SelectArrayInput,
    SelectInput
} from 'react-admin';
import {ReferenceInputProps, RaRecord} from 'react-admin';

interface CustomReferenceInputProps extends ReferenceInputProps {
    meta: {
        resource: string;
        model_name: string;
    };
    multiple?: boolean; // Добавляем параметр для поддержки множественного выбора
}

const CustomReferenceInput: React.FC<CustomReferenceInputProps> = (props) => {
    const {resource, meta: initialMeta, multiple = false, ...rest} = props;
    const dataProvider = useDataProvider();
    const [meta, setMeta] = useState(initialMeta);

    const handleSelectChange = async (event: any) => {
        if ('target' in event) {
            const selectedIds = event.target.value;
            setMeta((prevMeta) => ({
                ...prevMeta,
                resource: meta.resource,
                model: meta.model_name,
            }));

            // Получение данных о выбранных записях
            const response = await dataProvider.getMany(meta.resource, {
                ids: Array.isArray(selectedIds) ? selectedIds : [selectedIds],
                meta: {
                    ...meta,
                    queue: meta.resource,
                    model: meta.model_name,
                },
            });

            // Преобразование в массив объектов { id, name }
            const selectedItems = response.data.map((item: RaRecord) => ({
                id: item.id,
                name: item.name
            }));

            console.log("Selected records:", selectedItems);
        } else {
            console.log("Selected record:", event);
        }
    };

    let optionValue = "id";
    if (["languageCode", "roleCode", "genderCode", "periodTypeCode"].includes(rest.source)) {
        optionValue = "code";
    }

    let optionText = "name";

    return (
        <ReferenceInput {...rest}>
            {multiple ? (
                <SelectArrayInput
                    source={rest.source}
                    name={rest.name}
                    label={rest.label}
                    optionValue={optionValue}
                    optionText={optionText}
                    sx={{marginRight: '10px', fontSize: '10px'}}
                    onChange={handleSelectChange}
                />
            ) : (
                <SelectInput
                    source={rest.source}
                    name={rest.name}
                    label={rest.label}
                    optionValue={optionValue}
                    optionText={optionText}
                    sx={{marginRight: '10px', fontSize: '10px'}}
                    onChange={handleSelectChange}
                />
            )}
        </ReferenceInput>
    );
};

export default CustomReferenceInput;