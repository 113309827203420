import {
    BooleanInput,
    TextInput
} from 'react-admin';
import * as React from 'react';


const OrganizationRefForm: React.FC = () => {
    return (
        <div>

            <TextInput
                source="code"
                name="code"
                label="Код"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />

            <TextInput
                source="name"
                name="name"
                label="Название"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />

            <TextInput
                source="bin"
                name="bin"
                label="БИН"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />

            <TextInput
                source="phoneNumber"
                name="phoneNumber"
                label="Телефон"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />

            <TextInput
                source="address"
                name="address"
                label="Адрес"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />

            <BooleanInput
                source="active"
                name="active"
                label="Активен"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
        </div>
    );
};

export default OrganizationRefForm;
