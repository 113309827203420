import {required, TextInput, DateInput} from 'react-admin';
import * as React from 'react';
import CustomReferenceInput from "../elements/CustomReferenceInput";


const SymptomDataForm: React.FC = () => {
    return (
        <div>

            <TextInput
                source="subjectId"
                name="subjectId"
                label="Индификатор Subject"
                validate={[required()]}
                sx={{marginRight: '10px', fontSize: '10px'}}
            />

            <DateInput
                source="date"
                name="date"
                label="Дата"
                validate={[required()]}
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <TextInput
                source="symptomValue"
                name="symptomValue"
                label="Индификатор Symptom Value"
                validate={[required()]}
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <CustomReferenceInput
                source="symptomId"
                reference="admin-symptoms-ref-choices"
                label="Индификатор Symptom Id"
                name="symptomId"
                filter={{services: 'account_service', resource: "admin-symptoms-ref-list"}}
                meta={{resource: 'admin-symptoms-ref-choices', model_name: 'SymptomsRef'}}
            />
        </div>
    );
};

export default SymptomDataForm;
