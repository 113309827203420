import * as React from "react";
import {
    CreateButton,
    Datagrid,
    EditButton,
    ExportButton,
    FilterButton,
    List,
    NumberField,
    NumberInput,
    TextField, TextInput,
    TopToolbar
} from 'react-admin';
import DeleteCustomButton from "../elements/ButtonCustomDelete";


const PostListActions = () => (
    <TopToolbar>
        {/*<SelectColumnsButton />*/}
        <FilterButton/>
        <CreateButton/>
        <ExportButton/>
    </TopToolbar>
);


// Определение фильтров с использованием полей из модели SubjectResponse
const postFilters = [
    <TextInput name="text" label="Текст" source="text" defaultValue=""/>,
    <TextInput name="calendarId" label="Календарь" source="calendarId" defaultValue=""/>,
    <TextInput name="periodTypeId" label="Тип периода" source="periodTypeId" defaultValue=""/>,
    <NumberInput name="periodBegin" label="Начало периода" source="periodBegin" defaultValue=""/>,
    <NumberInput name="periodEnd" label="Конец периода" source="periodEnd" defaultValue=""/>,
    <TextInput name="image" label="Изображение" source="image" defaultValue=""/>
];

// Определение сервисов
const services = {'services': 'account_service'};

// Экспорт списка субъектов с использованием обновленных полей
export const calendarPeriodRefList = (props: any) => (
    <List
        {...props}
        actions={<PostListActions/>}
        filters={postFilters}
        filter={services}
    >
        <Datagrid>
            <TextField source="calendar.name" label="Календарь"/>
            <TextField source="periodType.name" label="Тип периода"/>
            <NumberField source="periodBegin" label="Начало периода"/>
            <NumberField source="periodEnd" label="Конец периода"/>
            <EditButton/>
            <DeleteCustomButton
                queue="admin-get-calendar-period-ref-for-delete"
                type="delete"
                redirect_path="/admin-calendars-periods-ref-list"
            />
        </Datagrid>
    </List>
);


