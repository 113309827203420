import * as React from 'react';
import {SimpleForm} from 'react-admin';
import useCustomCreateController from '../../hooks/UseCustomCreateControllerHook';
import PeriodTypeRefForm from '../forms/PeriodTypeRefForm';


export const PeriodTypeRefFormCreate = (props: any) => {
    const {isLoading, handleClick} = useCustomCreateController({
        queue: 'admin-get-period-type-ref',
        type: 'create',
        redirect_path: 'admin-periods-type-ref-list'
    });

    if (isLoading) return null;

    return (
        <div>
            <SimpleForm onSubmit={handleClick}>
                <PeriodTypeRefForm/>
            </SimpleForm>
        </div>
    );
};


export default PeriodTypeRefFormCreate