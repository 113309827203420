import {ImageField, ImageInput, required, TextInput, DateInput} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import * as React from 'react';
import CustomReferenceInput from "../elements/CustomReferenceInput";


const AdviceRefForm: React.FC = () => {
    return (
        <div>

            <TextInput
                source="code"
                name="code"
                label="Код"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />
            <RichTextInput source="text" />
            <ImageInput
                accept="image/*"
                multiple={false}
                source="image"
                name="image"
                label="Изображение"
                placeholder={<p>Перетащите изображение</p>}>
                <ImageField source="src" title="title"/>
            </ImageInput>
            <CustomReferenceInput
                source="languageCode"
                reference="admin-languages"
                label="Язык"
                name="languageCode"
                filter={{services: 'account_service', resource: "admin-languages-list"}}
                meta={{resource: 'admin-languages', model_name: 'LanguagesRef'}}
            />
            <CustomReferenceInput
                source="adviceRuleRefId"
                reference="admin-advices-rule-ref"
                label="Правило"
                name="adviceRuleRefId"
                filter={{services: "account_service", resource: "admin-advices-rule-ref-list"}}
                meta={{resource: "admin-advices-rule-ref", model_name: "AdviceRuleRef"}}
            />
            <DateInput
                source="publicDate"
                name="publicDate"
                label="Дата публикации"
                sx={{marginRight: '10px', fontSize: '10px'}}
            />

        </div>
    );
};

export default AdviceRefForm;
