import * as React from "react";
import {
    CreateButton,
    Datagrid,
    EditButton,
    ExportButton,
    FilterButton,
    List,
    TextField,
    RichTextField,
    TextInput,
    TopToolbar
} from 'react-admin';
import DeleteCustomButton from "../elements/ButtonCustomDelete";


const PostListActions = () => (
    <TopToolbar>
        {/*<SelectColumnsButton />*/}
        <FilterButton/>
        <CreateButton/>
        <ExportButton/>
    </TopToolbar>
);


// Определение фильтров с использованием полей из модели SubjectResponse
const postFilters = [
    <TextInput name="code" label="Код" source="code" defaultValue=""/>,
    <TextInput name="text" label="Текст" source="text" defaultValue=""/>,
    <TextInput name="languageId" label="Язык" source="languageId" defaultValue=""/>
];

// Определение сервисов
const services = {'services': 'account_service'};

// Экспорт списка субъектов с использованием обновленных полей
export const adviceRefList = (props: any) => (
    <List
        {...props}
        actions={<PostListActions/>}
        filters={postFilters}
        filter={services}
    >
        <Datagrid>
            <TextField source="code" label="Код"/>
            <RichTextField source="text" label="Текст"/>
            <TextField source="language.name" label="Язык"/>
            <TextField source="publicDate" label="Дата публикации"/>
            <EditButton/>
            <DeleteCustomButton
                queue="admin-get-advice-ref-for-delete"
                type="delete"
                redirect_path="/admin-advice-ref-list"
            />
        </Datagrid>
    </List>
);


