import * as React from 'react';
import {SimpleForm} from 'react-admin';
import useCustomEditController from "../../hooks/UseCustomEditControllerHook";
import CalendarPeriodRefForm from '../forms/CalendarPeriodRefForm';


export const CalendarPeriodRefFormEdit = (props: any) => {
    const {record, isLoading, handleClick} = useCustomEditController(
        {queue: 'admin-get-calendar-period-ref', type: 'edit'}
    );

    if (isLoading) return null;

    return (
        <div>
            <SimpleForm record={record} onSubmit={handleClick}>
                <CalendarPeriodRefForm/>
            </SimpleForm>
        </div>

    )
};

export default CalendarPeriodRefFormEdit
