import * as React from "react";
import {
    CreateButton,
    Datagrid,
    EditButton,
    ExportButton,
    FilterButton,
    List,
    NumberInput,
    TextField, TextInput,
    TopToolbar
} from 'react-admin';
import DeleteCustomButton from "../elements/ButtonCustomDelete";


const PostListActions = () => (
    <TopToolbar>
        {/*<SelectColumnsButton />*/}
        <FilterButton/>
        <CreateButton/>
        <ExportButton/>
    </TopToolbar>
);


// Определение фильтров с использованием полей из модели SubjectResponse
const postFilters = [
    <TextInput name="calendarId" label="Календарь" source="calendarId" defaultValue=""/>,
    <TextInput name="periodTypeId" label="Тип периода" source="periodTypeId" defaultValue=""/>,
    <NumberInput name="period" label="Период" source="period" defaultValue=""/>,
    <TextInput name="eventTypeId" label="Тип события" source="eventTypeId" defaultValue=""/>
];

// Определение сервисов
const services = {'services': 'account_service'};

// Экспорт списка субъектов с использованием обновленных полей
export const eventRefList = (props: any) => (
    <List
        {...props}
        actions={<PostListActions/>}
        filters={postFilters}
        filter={services}
    >
        <Datagrid>
            <TextField source="calendar.name" label="Календарь"/>
            <TextField source="periodType.name" label="Тип периода"/>
            <TextField source="period" label="Период"/>
            <TextField source="eventType.name" label="Тип события"/>
            <EditButton/>
            <DeleteCustomButton
                queue="admin-get-event-ref-for-delete"
                type="delete"
                redirect_path="/admin-events-ref-list"
            />
        </Datagrid>
    </List>
);


